import {
  Container,
  Box,
  VStack,
  Text,
  Link,
  HStack,
  StackDivider,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

export default function Footer() {
  return (
    <Box
      as={motion.div}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition="1s linear"
      viewport={{ once: true }}
    >
      <Container maxW={{ base: 'full', md: '8xl' }} py={5}>
        <VStack spacing={5}>
          <HStack spacing={2} divider={<StackDivider borderColor="gray.600" />}>
            <Link
              fontSize={['xs', 'sm']}
              fontWeight={600}
              color="white"
              _hover={{ textDecor: 'underline' }}
              href="/terms"
            >
              Política de privacidade
            </Link>

            <Link
              fontSize={['xs', 'sm']}
              fontWeight={600}
              color="white"
              _hover={{ textDecor: 'underline' }}
              href="/regulations"
            >
              Regulamento
            </Link>

            <Link
              fontSize={['xs', 'sm']}
              fontWeight={600}
              color="white"
              _hover={{ textDecor: 'underline' }}
              href="/faq"
            >
              Dúvidas?
            </Link>
          </HStack>
          <Text color="white" fontSize={['xs', 'sm']} textAlign="center">
            Participações válidas das 0h do dia 14.11.2022 até às 10h59m do dia
            18.12.2022. Consulte formas de participação e Regulamentos completos
            no site https://eusoucraque.com.br. Imagens ilustrativas.
            Certificados de Autorização SEAE/ME 04.023881/2022 e 05.023920/2022.
            *Por dia de participação. **Entregue em crédito PicPay, sem direito
            a saque. 1.Entregue em forma de voucher. Para maiores informações
            sobre as premiações, consulte o Regulamento.
          </Text>
        </VStack>
      </Container>
    </Box>
  );
}
