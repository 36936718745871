import { GameDetailType } from '@interfaces/Game';
import { Stage } from '@interfaces/Stage';
import logger from '@logger/logger';
import { getStagesReq } from '@services/stages';
import React, { createContext, useState } from 'react';

interface StagesContextInterface {
  stages: Stage[] | undefined;
  setStages({}: Stage[]): any;
  getQuizStage(): Stage | undefined;
  loadNewStages(): Promise<any>;
  loading: boolean;
}

interface StageInterfaceProps {
  children: React.Component | any;
  stages?: Stage[];
}

export const StagesContext = createContext<StagesContextInterface>(
  {} as StagesContextInterface
);

export default function StagesProvider({
  children,
  stages: initialStages,
}: StageInterfaceProps) {
  const [stages, setStages] = useState<Stage[] | undefined>(initialStages);
  const [loading, setIsLoading] = useState(false);

  const loadNewStages = async () => {
    logger.info('loadStates called');
    setIsLoading(true);
    try {
      const data = await getStagesReq();
      setStages(data as Stage[]);
      setIsLoading(false);
      return data as Stage[];
    } catch (e) {
      setIsLoading(false);
      return undefined;
    }
    // getStagesReq().then((data) => {
    //   console.log(data);
    // });
  };
  // console.log("stages",stages)

  const getQuizStage = () => {
    let stage;
    stage = stages?.find((v) => v.games[0]?.type == GameDetailType.Quiz);

    if (stage == null || stage == undefined) {
      return {
        id: 8,
        name: 'Quiz',
        start_date: '2022-11-14T00:00:00.000Z',
        end_date: '2022-12-30T11:00:00.000Z',
        next_draw: '',
        card_header_style: {
          icon_url: 'https://promozed-benja.vercel.app/icons/flame.svg',
          icon: 'flame',
          bg_color: '#fbe41b',
          font_color: '',
        },
        games: [
          {
            idPromoFase: 8,
            idStage: 8,
            idGame: 34,
            type: 6,
            name: 'Quiz',
            description: 'Quiz',
            status: 3,
            user_score:[],
            card_style:{
              icon:"",
              icon_url:"",
              bg_color:"",
              font_color:"",
              action_button_bg_color:"",
              action_button_font_color:"",
            }
          },
        ],
      }  as Stage;
    }
    return stage;
  };
  return (
    <StagesContext.Provider
      value={{
        stages,
        setStages,
        loadNewStages,
        getQuizStage,
        loading,
      }}
    >
      {children}
    </StagesContext.Provider>
  );
}
