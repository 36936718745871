import {
  Flex,
  Button,
  HStack,
  StackDivider,
  Link,
  Icon,
  VStack,
} from '@chakra-ui/react';
import {  MdMenu } from 'react-icons/md';
import UserProfile from '@components/UserProfile';
import { useSession } from 'next-auth/react';
import { RiArrowDownSFill } from 'react-icons/ri';

export default function Header({
  onOpen,
  ...rest
}: {
  onOpen: any;
  rest?: any;
}) {
  const { data: _session, status } = useSession();

  
  function renderActionButton() {
        return (
          <HStack spacing={[2,2,14,14]}>
          <Button
              rightIcon={<RiArrowDownSFill color='white' fontSize={"18px"} />}
              // colorScheme="blue"
              bgColor="#0455F2;"
              color={"white"}
              fontFamily={"Inter"}
              fontWeight={700}
              textAlign="center"
              justifyContent="center"
              size={['sm', 'sm']}
              onClick={() => {
                const scrollElem = document.getElementById('game-banner');
                scrollElem?.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})

              }}
            >
              Bolão
            </Button>
            <Button
              rightIcon={<RiArrowDownSFill color='#14284B' fontSize={"18px"} />}
              bgColor="#FFBB38"
              fontFamily={"Inter"}
              fontWeight={700}
              textAlign="center"
              justifyContent="center"
              size={['sm', 'sm']}
              onClick={() => {

                const scrollElem = document.getElementById('scrolling_quiz_game_div');
                scrollElem?.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})

              }}
            >
              Quiz
            </Button>
          </HStack>
        );

  }
  return (
    <Flex
      position="sticky"
      top="0"
      px={[5,10,15,20]}
      height={status === 'unauthenticated'?'80px':'90px'}
      zIndex="1"
      alignItems="center"
      backgroundColor="#1F1960"
      opacity={0.95}
      backdropFilter="saturate(180%) blur(5px)"
      justifyContent="space-between"
      {...rest}
    >
      <Icon
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        color="white"
        w={6}
        h={6}
        size="lg"
        aria-label="open menu"
        as={MdMenu}
        zIndex={1}
      />

      <HStack zIndex={1} spacing={2} divider={<StackDivider borderColor="gray.600" />}>
      {/* {status === 'unauthenticated' && (
        <Box
          display={{ base: 'none', md: 'flex' }}
          as={motion.div}
          dragConstraints={{ left: -100, right: 100 }}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          transition="0.05s linear"
          pr={5}
        >
          <Button
            borderRadius={10}
            colorScheme="orange"
            onClick={() => {
              router.push('/login');
            }}
            size="lg"
          >
            <Text color="white">Quero participar</Text>
          </Button>
        </Box>
      )} */}
        <Link
          fontWeight={600}
          color="white"
          _hover={{ textDecor: 'underline' }}
          display={{ base: 'none', md: 'flex' }}
          href="/regulations"
        >
          Regulamento
        </Link>

        <Link
          fontWeight={600}
          color="white"
          _hover={{ textDecor: 'underline' }}
          display={{ base: 'none', md: 'flex' }}
          href="/faq"
        >
          Dúvidas?
        </Link>
      </HStack>
      {status !== 'unauthenticated' && (
        <VStack
          position="absolute"
          marginLeft="auto"
          margin-right="auto"
          left={0}
          right={0}
          zIndex={0}
          spacing={1}
        >
          {renderActionButton()}
        </VStack>
      )}
      <UserProfile />
    </Flex>
  );
}
