import logger from '@logger/logger';
import httpConfig from '@lib/apiClientFetch';
import { Prize } from '@interfaces/Prize';


interface getPrizesProps {
  has_viewed?: boolean
  type?: number
}


export const getPrizesReq = async (props?:getPrizesProps): Promise<Prize[] | undefined> => {
  try {
    const response = await httpConfig.fetch.get(`/api/prizes`,{
      params:props
    });
    
    const prizeRes = response.data;
    logger.debug('prizeRes:', prizeRes);

    return prizeRes;
  } catch (error) {
    logger.error(error);
    // throw new Error("Failed on GetGameDetailReq:" + error);
    return undefined;
  }
};