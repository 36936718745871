import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import { formatDate, formatHour, getDayOfWeek, padTo2Digits } from '@lib/date';
import { isMatchBlocked } from '@lib/validadeMatch';
import { useEffect, useState } from 'react';
import CustomInputMatch from '../CustomInputMatch';

const MatchComponent = (props: any) => {
  const { match, isDisabled, onChange, isNoModal } = props;
  const teamAInfos = match != null ? match.teams[0] : {};
  const teamBInfos = match != null ? match.teams[1] : {};
  const pointsWon = match != null ? match.score[1] && match.score[1].value : 0;

  const [inputInfos, setInputInfos] = useState<{
    teamA: number | null;
    teamB: number | null;
  }>({
    teamA: teamAInfos.guess,
    teamB: teamBInfos.guess,
  });
  const [inputInfosFlags, setInputInfosFlags] = useState<{
    teamA: string;
    teamB: string;
  }>({
    teamA: '',
    teamB: '',
  });
  useEffect(() => {
    if (match?.teams != null && match?.teams.length > 0) {
      teamAInfos.flag =
        teamAInfos.flag != null && teamAInfos.id != null
          ? `/flags/ids/id-${padTo2Digits(teamAInfos.id)}.svg`
          : null;
      teamBInfos.flag =
        teamBInfos.flag != null && teamBInfos.id != null
          ? `/flags/ids/id-${padTo2Digits(teamBInfos.id)}.svg`
          : null;
      const teamAURL = teamAInfos.flag ?? '/flags/xx.svg';
      const teamBURL = teamBInfos.flag ?? '/flags/xx.svg';
      setInputInfosFlags({ teamA: teamAURL, teamB: teamBURL });
    }
  }, []);
  useEffect(() => {
    if (isNoModal != true && document != null) {
      setTimeout(() => {
        var target = document.getElementById('input-teamA');
        if (target != null) {
          target.focus();
        }
      }, 500);
    }
  }, [match]);

  const date =
    match?.matchStart != null ? new Date(match.matchStart) : new Date();

  const showResults =
    isNoModal &&
    teamAInfos.result != undefined &&
    teamAInfos.result != null &&
    teamBInfos.result != undefined &&
    teamBInfos.result != null;

  // const now = new Date();
  // now.setHours(now.getHours() - 1);
  // const maxGuessDate = match?.matchStart != null ? new Date(match.matchStart) : new Date();
  // maxGuessDate.setHours(maxGuessDate.getHours() - 1);

  // const isBlocked =
  //   teamAInfos.id == null ||
  //   teamBInfos.id == null ||
  //   maxGuessDate.getTime() <= now.getTime();

  const isBlocked = isMatchBlocked(match);
  useEffect(() => {
    if (onChange != undefined) onChange(inputInfos);
  }, [inputInfos]);

  useEffect(() => {
    setInputInfos({
      teamA: teamAInfos.guess,
      teamB: teamBInfos.guess,
    });
  }, [teamBInfos, teamAInfos]);

  return (
    <VStack
      py={isBlocked ? [5, 5] : [2, 5]}
      fontSize={['sm', 'md', 'md', 'lg']}
      alignItems="center"
      textAlign="center"
      width="-webkit-fill-available"
      color={isBlocked ? 'gray' : 'white'}
      cursor={isNoModal ? (isBlocked ? 'not-allowed' : 'pointer') : 'auto'}
    >
      <HStack>
        <Text fontSize={[13, 14, 17]} fontWeight="bold">{`${getDayOfWeek(
          date.getDay()
        )} ${formatDate(date)}`}</Text>
        <Text fontSize={[13, 14, 17]} color={isBlocked ? 'gray' : 'gray.300'}>
          {' '}
          - {`${match?.place.toUpperCase()}`} -{' '}
        </Text>
        <Text fontSize={[13, 14, 17]} fontWeight="bold">{`${formatHour(
          date
        )} `}</Text>
      </HStack>
      <HStack alignItems="center" spacing={[2, 5]}>
        <HStack>
          <HStack>
            <Text fontWeight="bold" fontSize={20}>
              {teamAInfos.name}
            </Text>
            {teamAInfos.id != null && (
              <>
                <Image src={inputInfosFlags.teamA} h={'40px'} w={'40px'} />
                <CustomInputMatch
                  id={'input-teamA'}
                  isDisabled={isDisabled}
                  isBlocked={isBlocked}
                  value={inputInfos.teamA}
                  onChange={(value: any) => {
                    setInputInfos({
                      ...inputInfos,
                      teamA: value,
                    });
                  }}
                />
              </>
            )}
          </HStack>
        </HStack>
        <Text fontWeight={'hairline'} fontSize={18} p={0} m={0}>
          X
        </Text>
        <HStack>
          <HStack>
            {teamBInfos.id != null && (
              <>
                <CustomInputMatch
                  value={inputInfos.teamB}
                  isDisabled={isDisabled}
                  isBlocked={isBlocked}
                  onChange={(value: any) => {
                    setInputInfos({
                      ...inputInfos,
                      teamB: value,
                    });
                  }}
                />
                <Image src={inputInfosFlags.teamB} h={'40px'} w={'40px'} />
              </>
            )}
            <Text fontWeight="bold" fontSize={20}>
              {teamBInfos.name}
            </Text>
          </HStack>
        </HStack>
      </HStack>
      {/* <Text fontSize="sm">{match.stadium}</Text> */}
      {showResults && (
        <HStack spacing={0} p={0} m={0}>
          <VStack spacing={0} p={0} m={0}>
            <Text
              fontSize={'22'}
              fontWeight="extrabold"
              color={'yellow'}
              pb={-5}
              mb={-2}
            >
              {teamAInfos.result} x {teamBInfos.result}
            </Text>
            <Text pt={0} mt={0}>
              resultado
            </Text>
          </VStack>
          <VStack spacing={0} p={0} m={0} pl={20}>
            <Text
              fontSize={'22'}
              fontWeight="extrabold"
              color={'yellow'}
              pb={-5}
              mb={-2}
            >
              {pointsWon}
            </Text>
            <Text pt={0} mt={0}>
              pontos
            </Text>
          </VStack>
        </HStack>
      )}
    </VStack>
  );
};

export default MatchComponent;
