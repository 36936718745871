import { Input, NumberInput, NumberInputField } from "@chakra-ui/react";
''
interface CustomInputMatchProps {
  value?: number | null;
  onChange(value: any): void;
  id?: string;
  isDisabled: boolean;
  isBlocked: boolean;
}


const CustomInputMatch = (props: CustomInputMatchProps) => {
  const { value, onChange, id, isDisabled, isBlocked } = props;

  const onFocus = (e: any) => {
    setTimeout(() => {
      e.target.select();
    }, 100);
  };


  if (isDisabled) {
    return (
      <Input
        size={'sm'}
        isReadOnly={true}
        color={"gray"}
        cursor={isBlocked ? "not-allowed": "pointer"}
        textAlign="center"
        fontWeight={'bold'}
        fontSize={20}
        p={0}
        minW={'25px'}
        maxW={'40px'}
        backgroundColor={isBlocked ? "gray": "white"}
        textColor="black"
        type="number"
        value={value ?? ''}
      />
    );
  }
  return (
    <NumberInput
      size={'sm'}
      minW={'25px'}
      maxW={'50px'}
      isDisabled={isDisabled}
      onChange={(_, value) => onChange(value)}
      defaultValue={value ?? ''}
      min={0}
      step={1}
      max={50}
      backgroundColor="white"
      textColor="black"
      
      onFocus={onFocus}
    >
      <NumberInputField id={id} p={1} textAlign="center" fontWeight={'bold'} fontSize={18} />
    </NumberInput>
  );
};


export default CustomInputMatch