import {
  Box,
  Divider,
  HStack,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  VisuallyHidden,
  VStack,
} from '@chakra-ui/react';
import { Stage } from '@interfaces/Stage';
import { memo, useEffect, useRef, useState } from 'react';
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from 'react-icons/md';
import MatchComponent from '../Match';
import stagesJson from './data.json';
import { useSession } from 'next-auth/react';
import { useStages } from '@hooks/useStages';
import { Match } from '@interfaces/Game';
import ModalComponent from '../Modal';
import { isMatchBlocked } from '@lib/validadeMatch';

export default memo(GamesComponent);

function GamesComponent() {
  const { status, data: session } = useSession();
  const toast = useToast();
  // const { push } = useRouter();
  const { stages: initialStages, loadNewStages } = useStages();
  const [ isFirstRender, setIsFirstRender] = useState(true);
  const [selectedMatch, setMatch] = useState<any>();
  const [tabIndex, setTabIndex] = useState(0);
  const [minute, setMinute] = useState("");
  const [windowProps, setWindowProps] = useState({
    clientWidth: 0,
    clientHeight: 0,
    offsetLeft: 0,
    offsetTop: 0,
  });
  const tabCompRef = useRef<HTMLDivElement>();

  const getPredictScoreStages = () => {
    let stages = initialStages?.filter(
      (stage) =>
        stage.games[0] != undefined &&
        stage.games[0].type != 6 &&
        stage.games[0].matches != undefined &&
        stage.games[0].matches.length > 0
    );
    if (stages == undefined || stages.length <= 0) {
      stages = (stagesJson as Stage[]).filter(
        (stage) =>
          stage.games[0] != undefined &&
          stage.games[0].type != 6 &&
          stage.games[0].matches != undefined &&
          stage.games[0].matches.length > 0
      );
    }
    const now = new Date();
    for (let i = 0; i < stages.length; i++) {
      const stage = stages[i];

      stages[i].games[0].matches = stage.games[0].matches?.sort(function (
        a,
        b
      ) {
        const aDate = a.matchStart != null ? new Date(a.matchStart) : now;
        const bDate = b.matchStart != null ? new Date(b.matchStart) : now;
        return aDate.getTime() - bDate.getTime();
      });
    }

    for (let i = 0; i < stages.length; i++) {
      const stage = stages[i];
      if(stage.games[0].matches == undefined){
        continue;
      }
      for (let j = 0; j < stage.games[0].matches?.length; j++) {
        stages[i].games[0].matches![j].matchStart =  stages[i].games[0].matches![j].matchStart.replace(".000Z","")
      }
    }
    return stages;
  };

  useEffect(() => {
    if (initialStages != null && initialStages.length > 2 && isFirstRender) {
      getNearGameMatch();
      setIsFirstRender(false)
    }
  }, [initialStages]);


  const getNearGameMatch = () => {
    let nearGameMatch = null as any;
    const stages = getPredictScoreStages();
    const now = new Date();
    for (let i = 0; i < stages.length && nearGameMatch == null; i++) {
      const stage = stages[i];
      let matches = [] as Match[];
      if (
        stage != null &&
        stage.games.length > 0 &&
        stage.games[0].matches != null &&
        stage.games[0].matches.length > 0
      ) {
        matches = stage.games[0].matches;
      }
      for (let j = 0; j < matches.length && nearGameMatch == null; j++) {
        const match = matches[j];
        const valueDate =
          match.matchStart != null ? new Date(match.matchStart) : now;
        if (
          !(valueDate.getTime() <= now.getTime())
        ) {
          nearGameMatch = {
            tabIndex: i,
            idMatch: match.idMatch,
          };
          break;
        }
      }
    }
    if (nearGameMatch != null && nearGameMatch.tabIndex != null) {
      setTabIndex(nearGameMatch.tabIndex);
    }
  };

  useEffect(() => {
    const idInterval = setInterval(() => {
      setMinute(new Date().getMinutes().toString())
    }, 60*1000);
    return () => {
      clearInterval(idInterval);
    };
  }, []);

  useEffect(() => {
    if (tabCompRef.current != null) {
      const { clientWidth, offsetLeft, offsetTop, clientHeight } =
        tabCompRef.current;
      setWindowProps({ clientWidth, offsetLeft, offsetTop, clientHeight });
    }
  }, [
    tabCompRef.current?.clientWidth,
    tabCompRef.current?.offsetLeft,
    tabCompRef.current?.offsetTop,
    tabCompRef.current?.clientHeight,
  ]);

  const handleOnClickGames = () => {
    if (status !== 'loading' && session == null) {
      toast({
        title: 'Promoção Encerrada.',
        description:
          'Não é possível jogar, aguarde novidades para jogar novamente!',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      })
    }
  };


  return (
    <Box
      ref={(ref) => {
        if (ref != undefined) tabCompRef.current = ref;
      }}
    >
      <ModalComponent
        selectedMatch={selectedMatch}
        loadNewStages={loadNewStages}
        setMatch={setMatch}
        windowProps={windowProps}
      />
      <VisuallyHidden>{minute}</VisuallyHidden>
      <Box boxShadow="md" py="5px">
        <HStack justifyContent="space-around" color="white">
          <IconButton
            aria-label="Left Tab"
            variant="ghost"
            icon={<MdOutlineArrowBackIos />}
            disabled={tabIndex < 1}
            onClick={() => setTabIndex(tabIndex - 1)}
          />
          <Text
            fontSize={['md', 'lg', 'xl']}
            fontWeight="bold"
            w={'210px'}
            textAlign="center"
          >
            {getPredictScoreStages()[tabIndex].name.toLocaleUpperCase()}
          </Text>
          <IconButton
            aria-label="Right Tab"
            variant="ghost"
            icon={<MdOutlineArrowForwardIos />}
            disabled={tabIndex >= getPredictScoreStages().length - 1}
            onClick={() => setTabIndex(tabIndex + 1)}
          />
        </HStack>
      </Box>
      <Box
        h={400}
        overflowX="hidden"
        overflowY="auto"
        borderRadius={10}
        id="scrolling_predict_game_div"
        sx={{
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': {
            display: selectedMatch != null ? 'none' : 'auto',
            width: '20px',
          },
          '::-webkit-scrollbar-button:single-button': {
            // backgroundColor: "#bbbbbb",
            display: 'block',
            borderStyle: 'solid',
            height: '20px',
            width: '5px',
          },

          '::-webkit-scrollbar-button:single-button:vertical:decrement': {
            borderWidth: '0 10px 10px 10px',
            borderColor: 'transparent transparent #d6dee1 transparent',
          },
          '::-webkit-scrollbar-button:single-button:vertical:decrement:hover': {
            borderColor: 'transparent transparent #a8bbbf transparent',
          },
          '::-webkit-scrollbar-button:single-button:vertical:increment': {
            borderWidth: '10px 10px 0 10px',
            borderColor: '#d6dee1 transparent transparent transparent',
          },
          '::-webkit-scrollbar-button:vertical:single-button:increment:hover': {
            borderColor: '#a8bbbf transparent transparent transparent',
          },
          '::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: '#d6dee1',
            borderRadius: '20px',
            border: '6px solid transparent',
            backgroundClip: 'content-box',
          },
          '::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#a8bbbf',
          },
        }}
        onClick={() => handleOnClickGames()}
      >
        <Tabs
          isLazy={true}
          size={['sm', 'md', 'lg']}
          colorScheme="whiteAlpha"
          color="white"
          index={tabIndex}
        >
          <TabList display={'none'}>
            {getPredictScoreStages().map((stage) => (
              <Tab key={stage.id}>{stage.name}</Tab>
            ))}
          </TabList>
          <TabPanels>
            {getPredictScoreStages().map((stage) => (
              <TabPanel key={stage.games[0].idGame}>
                <VStack>
                  {(stage.games[0] as any).matches.map((match: Match) => (
                    <Box
                      key={`${match.idMatch}`}
                      id={`match${match.idMatch}`}
                      w="-webkit-fill-available"
                      onClick={() => {
                        // const now = new Date();

                        // const maxGuessDate = match?.matchStart != null ? new Date(match.matchStart) : now;
                        // maxGuessDate.setHours(maxGuessDate.getHours() - 1);


                        // const teamAInfos = match != null ? match.teams[0] : { id: null };
                        // const teamBInfos = match != null ? match.teams[1] : { id: null };
                        // const isBlocked =
                        //   teamAInfos.id == null ||
                        //   teamBInfos.id == null ||
                        //   maxGuessDate.getTime() <= now.getTime();
                        const isBlocked = isMatchBlocked(match)
                        if (!isBlocked) {
                          setMatch({
                            ...match,
                            idStage: stage.id,
                            idGame: stage.games[0].idGame,
                          });
                        }

                      }}
                    >
                      <MatchComponent
                        match={match}
                        isDisabled={true}
                        isNoModal={true}
                      />
                      <Divider orientation="horizontal" />
                    </Box>
                  ))}
                </VStack>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
}
