import {
  Box,
  Center,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { WinnerPrize, WinnerPrizeDetail } from '@interfaces/Winners';
import {
  getWinnerPrizesDetailReq,
  getWinnerPrizesReq,
} from '@services/winner_prize';
import { useEffect, useState } from 'react';

export default function WinnerPrizes() {
  const SHOW_PRIZE_MESSAGE = false;
  const [winners, setWinners] = useState<WinnerPrize[]>();
  const [winnersPrize, setWinnersPrize] = useState<WinnerPrizeDetail[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWinner, setSelectedWinner] = useState<WinnerPrize | null>(
    null
  );
  const [isLargerThan400] = useMediaQuery('(min-width: 400px)');
  const { onClose } = useDisclosure();

  const handleSelectWinnerPrize = (winner: WinnerPrize) => {
    setSelectedWinner(winner);
    setIsLoading(true);
    setWinnersPrize([]);
    getWinnerPrizesDetailReq({
      idPrize: winner.idPrize,
      type: winner.type,
    }).then((value) => {
      if (value != undefined) {
        setWinnersPrize(value);
      }
      setIsLoading(false);
    });
  };

  const handleOnCloseModal = () => {
    onClose();
    setSelectedWinner(null);
  };

  useEffect(() => {
    if (!SHOW_PRIZE_MESSAGE) {
      getWinnerPrizesReq().then((value) => {
        if (value != undefined) {
          setWinners(value.sort((a,b)=>b.idPrize-a.idPrize));
          console.log("setWinners",winners)
          // console.log("setWinners",value.sort((a,b)=>(a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0)).sort((a,b)=>a.type-b.type))
        }
      });
    }
  }, []);

  const renderExibitionInfos = () => {
    if (isLargerThan400) {
      return (
        <TableContainer>
          <Table variant="striped">
            <TableCaption>
              Informações do(s) {winnersPrize?.length} ganhadore(s)
            </TableCaption>
            <Thead>
              <Tr>
                <Th>Email</Th>
                <Th>Status</Th>
                <Th>Data</Th>
              </Tr>
            </Thead>
            <Tbody>
              {winnersPrize
                ?.sort(function (a, b) {
                  const now = new Date();
                  const aDate =
                    a.dt_delivery != null ? new Date(a.dt_delivery) : now;
                  const bDate =
                    b.dt_delivery != null ? new Date(b.dt_delivery) : now;
                  return aDate.getTime() - bDate.getTime();
                })
                .map((e) => (
                  <Tr>
                    <Td>{e.login}</Td>
                    <Td>{e.status}</Td>
                    <Td>
                      {new Date(e.dt_delivery).toLocaleDateString("pt-BR") ?? ''}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      );
    }
    return (
      <VStack>
        {winnersPrize
          ?.sort(function (a, b) {
            const now = new Date();
            const aDate = a.dt_delivery != null ? new Date(a.dt_delivery) : now;
            const bDate = b.dt_delivery != null ? new Date(b.dt_delivery) : now;
            return aDate.getTime() - bDate.getTime();
          })
          .map((e) => (
            <VStack alignItems="start" w="full">
              <HStack>
                <Text fontWeight="bold">Email: </Text> <Text>{e.login}</Text>
              </HStack>
              <HStack>
                <Text fontWeight="bold">Status: </Text> <Text>{e.status}</Text>
              </HStack>
              <HStack>
                <Text fontWeight="bold">Data: </Text>{' '}
                <Text>
                  {new Date(e.dt_delivery).toLocaleDateString("pt-BR") ?? ''}
                </Text>
              </HStack>
              <Divider orientation='horizontal'  size="2xl"/>
            </VStack>
          ))}
      </VStack>
    );
  };

  if (SHOW_PRIZE_MESSAGE) {
    return (
      <Box background="linear-gradient(180deg, #FFFFFF 48.75%, rgba(255, 255, 255, 0) 153.44%);">
        <Flex w="full" h="full">
          <Container maxW={{ base: 'full', md: '9xl', lg: '8xl' }}>
            <VStack spacing={5}>
              <Heading
                color="black"
                fontSize={{ base: '24px', md: '32px', lg: '44px' }}
                p={[5, 5, 5, 5]}
                textAlign="center"
              >
                GANHADORES DA PROMOÇÃO
              </Heading>

              <SimpleGrid columns={2} spacing={[5, 5, 5, 80]}>
                <Center>
                  <Text
                    fontSize={{ base: '14px', md: '18px', lg: '26px' }}
                    align="center"
                    fontWeight={700}
                  >
                    Devido à não realização da extração da Loteria Federal no
                    dia 21/12/2022, informamos que, para esta promoção, será
                    utilizada a extração do próximo sorteio, que será realizado
                    no próximo sábado, dia 24/12/2022.
                  </Text>
                </Center>
                <Box p={0} m={0}>
                  <Image src="imgs/trophy.png" />
                </Box>
              </SimpleGrid>
            </VStack>
          </Container>
        </Flex>
      </Box>
    );
  }

  return (
    <>
      <Modal
        size="full"
        isOpen={selectedWinner != null}
        onClose={handleOnCloseModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">{selectedWinner?.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading ? (
              <VStack>
                <Spinner size="xl" speed="0.65s" />
                <Text fontSize="sm">aguarde</Text>
              </VStack>
            ) : (
              renderExibitionInfos()
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box background="linear-gradient(180deg, #FFFFFF 48.75%, rgba(255, 255, 255, 0) 153.44%);">
        <Flex w="full" h="full" flexDir="column">
          <VStack spacing={5} w="full" h="full">
            <HStack
              alignItems="center"
              justifyContent={'end'}
              w="full"
              h="20vh"
            >
              <VStack
                color="#1F1960"
                position="absolute"
                marginLeft="auto"
                margin-right="auto"
                left={0}
                right={0}
                zIndex={0}
                spacing={1}
              >
                <Heading
                  fontSize={{ base: '24px', md: '32px', lg: '44px' }}
                  px={[5, 5, 5, 5]}
                  textAlign="center"
                >
                  GANHADORES DA PROMOÇÃO
                </Heading>
                <Text
                  fontSize={{ base: '14px', md: '18px', lg: '26px' }}
                  align="center"
                  fontWeight={500}
                >
                  Selecione o prêmio para ver os ganhadores:
                </Text>
              </VStack>
              <Image src="imgs/trophy.png" h={['10vh', '20vh']} />
            </HStack>
          </VStack>
          <Flex
            mx="15vw"
            flexFlow={['column', 'row wrap']}
            justifyContent={['center', 'space-around']}
            alignContent="space-around"
            alignItems="center"
          >
            {winners?.map((winner) => {
              return (
                <Image
                  key={winner.idPrize}
                  w={winner.type == 1 ? ['80vw', '22vw'] : ['80vw', '18vw']}
                  my="1vh"
                  mx={winner.type == 1 ?"3vw":""}
                  onClick={() => handleSelectWinnerPrize(winner)}
                  cursor="pointer"
                  shadow="lg"
                  _hover={{
                    shadow: 'sm',
                  }}
                  borderRadius="14%"
                  src={winner.description}
                />
              );
            })}
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
