import { Match } from '@interfaces/Game';

export function isMatchBlocked(match?: Match) {
const now = new Date();

const maxGuessDate = match?.matchStart != null ? new Date(match.matchStart) : now;
maxGuessDate.setHours(maxGuessDate.getHours() - 1);

  let teamAId = null as any;
  let teamBId = null as any;
  if (match != null && match.teams != null && match.teams.length == 2) {
    teamAId = match.teams[0].id;
    teamBId = match.teams[1].id;
  }

  return (
    teamAId == null ||
    teamBId == null ||
    maxGuessDate.getTime() <= now.getTime()
  );
}
