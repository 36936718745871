import {
  Box,
  Button,
  HStack,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';

import httpConfig from '@lib/apiClientFetch';
import MatchComponent from '../Match';
import logger from '@logger/logger';

const ModalComponent = ({
  selectedMatch,
  loadNewStages,
  setMatch,
  windowProps,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [inputInfos, setInputInfos] = useState<{
    teamA: number | null;
    teamB: number | null;
  }>({ teamA: null, teamB: null });

  const handleSendConfirmation = async () => {
    const now = new Date();
    const valueDate =
      selectedMatch.matchStart != null
        ? new Date(selectedMatch.matchStart)
        : now;
    if (
      valueDate.getTime() <= now.getTime() ||
      !(
        Number.isInteger(inputInfos.teamA) && Number.isInteger(inputInfos.teamB)
      )
    ) {
      loadNewStages();
      setMatch(null);
      return;
    }

    setIsLoading(true);
    try {
      const guessTeamA =
        inputInfos.teamA != null && inputInfos.teamA > 50
          ? 50
          : inputInfos.teamA;
      const guessTeamB =
        inputInfos.teamB != null && inputInfos.teamB > 50
          ? 50
          : inputInfos.teamB;
      const response = await httpConfig.fetchWithInterceptor.post(
        `/api/games/predictscoresoccer/question`,
        {
          idGame: selectedMatch.idGame,
          idStage: selectedMatch.idStage,
          idMatch: selectedMatch.idMatch,
          answers: [
            {
              idTeam: selectedMatch.teams[0].id,
              guess: guessTeamA,
            },
            {
              idTeam: selectedMatch.teams[1].id,
              guess: guessTeamB,
            },
          ],
        }
      );
      if (response.status == 200) {
        loadNewStages();
      } else {
        toast({
          title: 'Falha!',
          description: 'Falha ao enviar palpite!',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (e) {
      logger.error(e);
      toast({
        title: 'Falha!',
        description: 'Falha ao enviar palpite!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    setIsLoading(false);
    setMatch(null);
  };

  if (selectedMatch == null) {
    return <></>
  }

  return (
    <Box
      display={'flex'}
      position="absolute"
      height={`${windowProps.clientHeight ?? 0}px`}
      width={`${windowProps.clientWidth ?? 0}px`}
      bgColor={'rgba(6, 6, 6, 0.9)'}
      left={`${windowProps.offsetLeft}px`}
      top={`${windowProps.offsetTop ?? 0}px`}
      // display="flex"
      alignItems="center"
      justifyContent="center"
      color="white"
      borderRadius={10}
      zIndex={2}
    >
      <VStack
        bgColor="#201961"
        border="3px solid #FFFFFF"
        borderRadius="10px"
        p={2}
      >
        <MatchComponent
          match={selectedMatch}
          onChange={(value: { teamA: number; teamB: number }) => {
            setInputInfos(value);
          }}
        />
        <HStack>
          <Button
            colorScheme="blue"
            disabled={
              !(
                Number.isInteger(inputInfos.teamA) &&
                Number.isInteger(inputInfos.teamB)
              )
            }
            onClick={() => handleSendConfirmation()}
            isLoading={isLoading}
          >
            Confirmar
          </Button>
          <Button colorScheme="red" onClick={() => setMatch(null)}>
            Cancelar
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

export default ModalComponent;
