export function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

export function formatDate(date: Date) {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('/');
}

export function formatHour(date: Date) {
  return [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes())].join(
    ':'
  );
}

export function getDayOfWeek(dayOfWeek: number) {
  switch (dayOfWeek) {
    case 0:
      return 'DOM';
    case 1:
      return 'SEG';
    case 2:
      return 'TER';
    case 3:
      return 'QUA';
    case 4:
      return 'QUI';
    case 5:
      return 'SEX';
    case 6:
      return 'SAB';
  }
}
