import {
  // Box,
  HStack,
  Spinner,
  // IconButton,
  // Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Statistics as StatisticsReq } from '@interfaces/PredictScoreSoccer';
import { getStatisticsReq } from '@services/statistics';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
// import { MdRefresh } from 'react-icons/md';

const Statistics = () => {
  const [infos, setInfos] = useState<StatisticsReq>();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const {push} = useRouter();
  useEffect(()=>{
    loadStatiscsInfos();
  },[])

  const loadStatiscsInfos = async () => {
    setIsloading(true);
    getStatisticsReq().then((value)=>{
      if(value != undefined){
        setInfos(value)
      }
      setIsloading(false);
    });
  }


  const ScoreItem = ({name,value}:{name:string,value:string})=>{
    return (
      <HStack width="-webkit-fill-available" justifyContent="space-between">
        <Text>{name}</Text>
        {isLoading?
        <Spinner size='sm' speed='0.65s' alignSelf={"center"} />
        :<Text width={["80px"]} textAlign="center"  fontWeight="bold" color="#FFFF00" >{value}</Text>}
      </HStack>
    )
  }

  const RankingItem = ({name,value}:{name:string,value:string})=>{
    return (
      <HStack width="-webkit-fill-available" justifyContent="space-between" >
        <Text>{name}</Text>
        {isLoading?
        <Spinner size='sm' speed='0.65s' alignSelf={"center"} />
        :<Text width={["80px"]} fontWeight="bold" color="#00C2FF" >{value}</Text>}
      </HStack>
    )
  }

  // if(isLoading){
  //   return (
  //     <Center my={[10, 10, 20]} minH="440px">
  //       <Spinner size='xl' speed='0.65s' alignSelf={"center"} />
  //     </Center>
  //   )
  // } 

  return (
    <VStack
      bgColor="#201A64"
      fontSize={['sm', 'md', 'md', 'lg']}
      alignItems="center"
      textAlign="center"
      border="3px solid #FFFFFF"
      borderRadius="10px"
      width="-webkit-fill-available"
      minH={200}
      color="white"
      p={2}
    >
      <HStack width="-webkit-fill-available" justifyContent="center">
        {/* <Box w="20px"/> */}
        <Text fontWeight="bold" fontSize={['md', 'lg', 'xl']}>MEU PLACAR</Text>
        {/* <IconButton 
        alignSelf="end"
        aria-label='Refresh' 
        icon={<MdRefresh />}
        isLoading={isLoading}
        variant="ghost"
        fontSize={['25px', '30px']}
        onClick={() => {
          loadStatiscsInfos()
        }} 
      />*/}
      </HStack>
      <VStack 
        bgColor="#0066DE"
        borderRadius="8px"
        fontSize={['xs', 'sm', 'md']} 
        width="-webkit-fill-available"
        spacing={0}
        p={2}>
        <HStack width="-webkit-fill-available" mb={2} ><Text fontWeight="bold" >Pontuação</Text></HStack>
        <ScoreItem name="Pontos Ganhos" value={`${infos?.score?.points ?? "0"}`}/>
        <ScoreItem name="Aproveitamento" value={`${infos?.score?.yield ?? "0"}%`}/>
        <ScoreItem name="Minha posição no ranking" value={`${infos?.score?.ranking_position ?? "0"}°`}/>
        <ScoreItem name="Acertos na cabeça" value={`${infos?.score?.hits_correct ?? "0"}`}/>
        <ScoreItem name="Acertos parciais" value={`${infos?.score?.hits_partial ?? "0"}`}/>
        <ScoreItem name="Erros" value={`${infos?.score?.wrong ?? "0"}`}/>
        <ScoreItem name="Jogos pendentes" value={`${infos?.score?.pending_games ?? "0"}`}/>
      </VStack>
      <VStack 
        bgColor="#7B00A7"
        borderRadius="8px"
        fontSize={['xs', 'sm', 'md']} 
        width="-webkit-fill-available"
        spacing={0}
        p={2}>
        <HStack width="-webkit-fill-available" mb={2} ><Text fontWeight="bold" >Ranking</Text></HStack>
        <RankingItem name="Total de jogadores" value={`${infos?.ranking?.players_total ?? "0"}`}/>
        <RankingItem name="Jogadores a sua frente" value={`${infos?.ranking?.players_front ?? "0"}`}/>
        <RankingItem name="Jogadores empatados com você" value={`${infos?.ranking?.players_tied ?? "0"}`}/>
        <RankingItem name="Jogadores atrás de você" value={`${infos?.ranking?.players_behind ?? "0"}`}/>
      </VStack>

      <HStack  
        fontWeight="bold" 
        fontSize={['sm', 'md', 'lg']}
        width="-webkit-fill-available" 
        justifyContent={"space-around"}>
        <Text cursor="pointer" onClick={()=>{push("/account/prizes")}} >Meus prêmios</Text>
        <Text cursor="pointer"  onClick={()=>{push("/account/luckynumbers")}}>Meus números da sorte</Text>
      </HStack>
    </VStack>
  );
};

export default Statistics;
