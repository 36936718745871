import { Statistics } from '@interfaces/PredictScoreSoccer';
import logger from '@logger/logger';
import httpConfig from '@lib/apiClientFetch';

export const getStatisticsReq = async (): Promise<Statistics | undefined> => {
  try {
    const response = await httpConfig.fetchWithInterceptor.get(`/api/games/predictscoresoccer/info`);

    const statisticsRes: Statistics = response.data;
    logger.debug('statisticsRes:', statisticsRes);

    return statisticsRes;
  } catch (error) {
    logger.error(error);
    // throw new Error("Failed on GetGameDetailReq:" + error);
    return undefined;
  }
};
