import PrizesProvider from '@context/prizesContext';
import StagesProvider from '@context/stagesContext';
import { ThemeNames } from '@context/themeContext';
import { useTheme } from '@hooks/useTheme';
import Layout from '@layout';
import LandingPageLayout from '@layout/LandingPage';
import Home from '@modules/Home';
import { useRouter } from 'next/router';
import { ReactElement, useEffect } from 'react';

export default HomePage;

HomePage.getLayout = function getLayout(page: ReactElement) {
  return (
    <StagesProvider>
      <Layout>
        <LandingPageLayout>{page}</LandingPageLayout>
      </Layout>
    </StagesProvider>
  );
};

function HomePage() {
  const router = useRouter();
  const { setTheme } = useTheme();

  useEffect(() => {
    setTheme(ThemeNames.default);
  }, []);

  useEffect(() => {
    if (router.query != null && router.query.redirectUrl) {
      let redirectUrl = String(router.query.redirectUrl);
      if (
        redirectUrl != undefined &&
        redirectUrl != '' &&
        redirectUrl != null
      ) {
        router.push(Buffer.from(redirectUrl, 'base64').toString('ascii'));
      }
    }
  }, []);

  return (
    <PrizesProvider>
      <Home />
    </PrizesProvider>
  );
}
